"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
// import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
// import * as apiActions from 'api-actions'
const reducerKey = 'site-initialization';
exports.reducerKey = reducerKey;
// const slice = 'root.site-initialization'
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({
// [apiActions.fetchSiteInitializations]: setOnSuccess('siteInitializations'),
// [apiActions.fetchSiteInitialization]: setOnSuccess('siteInitialization'),
// [actions.clearSiteInitialization]: unsetState('siteInitialization'),
}, initialState);
exports.reducer = reducer;
// const select = selectorForSlice(slice)
const selectors = {
// siteInitializations: select('siteInitializations'),
// siteInitialization: select('siteInitialization'),
};
exports.selectors = selectors;
