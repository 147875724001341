"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveVerificationInfo = exports.gotoFailedPath = exports.gotoSuccessPath = exports.createFormUrl = exports.processStatus = void 0;
const config_1 = require("config");
// Process Status
const processStatus = (postRes) => {
    if (postRes) {
        if (postRes.status === 'verified') {
            return 'verified';
        }
        else if (postRes.status === 'failed') {
            return 'failed';
        }
        else {
            return false; // Continue Polling
        }
        // if (postRes.clicked === false) {
        //   return false // Continue Polling
        // }
        // if (postRes.clicked === true && postRes.verified === true) {
        //   return '/credit-portal/prove/apply' // Go to pre-fill
        // } else if (postRes.clicked === true && postRes.verified === false) {
        //   return '/credit-portal/apply/wizard' // Go to legacy application
        // }
    }
};
exports.processStatus = processStatus;
const createFormUrl = (postRes) => {
    const storeNum = postRes.ads_store_number;
    if (postRes?.location_id !== null && storeNum) {
        return (0, config_1.makeStoreUrl)(Number(storeNum));
    }
    if (postRes?.credit_application_id !== null) {
        return (0, config_1.makeStoreUrl)(Number(storeNum));
    }
    return '';
};
exports.createFormUrl = createFormUrl;
const gotoSuccessPath = ({ formUrl, setPlaying, setUrlLink, setSuccess, history, }) => {
    setPlaying(false);
    setUrlLink(formUrl);
    setSuccess(true);
    history.push(formUrl); // Redirect upon success
};
exports.gotoSuccessPath = gotoSuccessPath;
const gotoFailedPath = ({ formUrl, setPlaying, setUrlLink, setFailed, history, }) => {
    setPlaying(false);
    setUrlLink('');
    setFailed(true);
    history.push(formUrl);
};
exports.gotoFailedPath = gotoFailedPath;
const saveVerificationInfo = (postRes, dispatch, setProveVerify) => {
    dispatch(setProveVerify({
        credit_application_id: postRes.credit_application_id ?? undefined,
        location_id: postRes.location_id ?? undefined,
        session_id: postRes.session_id ?? postRes?.transaction.session_id,
        phone_number: postRes.phone_number ?? postRes?.transaction.phone,
        ssn_last_4: postRes.ssn_last_4 ?? postRes?.transaction.ssn_last_4,
        status: postRes?.status,
    }));
};
exports.saveVerificationInfo = saveVerificationInfo;
