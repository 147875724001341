"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOptions = void 0;
/**
 * @public
 * @name filterOptions
 * @description custom function for the Select component (react-select) to filter csv lists
 * @param candidate
 * @param input
 * @returns
 */
const filterOptions = (candidate, input) => {
    const labelLowercased = String(candidate.label).toLocaleLowerCase();
    const labelValue = candidate.value;
    // Multi-Search
    if (input.includes(',')) {
        const inputSplit = String(input).split(',');
        const isListNum = input.search(/([0-9])\w+/g); // is the array a list of store numbers
        const isListNames = input.search(/([a-z])\w+/g); // is the array a list of store numbers
        let checkOnly = false;
        if (isListNum >= 0 && isListNames < 0) {
            checkOnly = true; //inputSplit.length !== isListNum // dont match against the location id's
        }
        // Filter list vs search input
        const multiList = inputSplit.filter((itm) => {
            if (checkOnly) {
                if (checkSelectMatch(labelLowercased, labelValue, itm, checkOnly))
                    return true;
            }
            else {
                if (checkSelectMatch(labelLowercased, labelValue, itm))
                    return true;
            }
        });
        return !!multiList.length;
        // Single-Search
    }
    else {
        if (checkSelectMatch(labelLowercased, labelValue, input))
            return true;
    }
    return false; // default return required
};
exports.filterOptions = filterOptions;
/**
 * @private
 * @name checkSelectMatch
 * @description Check label or value match search input, or just id's
 */
const checkSelectMatch = (label, value, input, checkOnly = false) => {
    if (checkOnly) {
        // v2 - Checks only the end portion of the label
        const labelSplit = label.split(',');
        const lastItm = labelSplit.pop();
        if (String(lastItm).trim() == String(input).trim()) {
            return true;
        }
        else {
            return false;
        }
        // v1 - Checks whole string
        // return label.includes(input.toLocaleLowerCase()) // look for store numbers
    }
    if (label.includes(input.toLocaleLowerCase()) ||
        Number(value) == Number(input) ||
        arrayStringsIntersect(label, input)) {
        return true;
    }
};
/**
 * @private
 * @name arrayStringsIntersect
 * @description Finds common words between two strings
 */
const arrayStringsIntersect = (arr1, arr2) => {
    const arr2Split = arr2.split(' ');
    const hasLength = arr1.split(' ').filter((x) => arr2Split.includes(x));
    if (hasLength.length) {
        return hasLength.length > 0 ? !!hasLength.length : false;
    }
    else {
        return false;
    }
};
