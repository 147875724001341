"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
// import PropTypes from 'prop-types'
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
// import { Spinner } from 'lp-components'
// import { connectParams } from 'lp-hoc'
// import { selectors } from '../reducer'
// import * as actions from '../actions'
// import * as apiActions from 'api-actions'
// import * as Types from 'types'
function LegalShow() {
    // {
    //   id,
    //   legal,
    //   fetchLegal,
    //   clearLegal,
    // }
    // useEffect(() => {
    //  fetchLegal(id)
    //  // Cleanup
    //  return () => clearLegal()
    // }, [ id ])
    // if (!legal) return <Spinner />
    return (0, jsx_runtime_1.jsx)("div", { children: " This is the Legal show view! " });
}
function mapStateToProps( /* state */) {
    return {
    // legal: selectors.legal(state)
    };
}
const mapDispatchToProps = {
// fetchLegal: apiActions.fetchLegal,
// clearLegal: actions.clearLegal
};
exports.default = (0, redux_1.compose)(
// connectParams('id'),
(0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(LegalShow);
