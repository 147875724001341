"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("../components");
const config_1 = require("config");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const actions = __importStar(require("../actions"));
const reducer_1 = require("../reducer");
const lp_redux_api_1 = require("lp-redux-api");
function Practice({ setPractice, practicesList, loading, setPracticesList, }) {
    const history = (0, react_router_dom_1.useHistory)();
    const handlePracticeSelection = (practice) => {
        setPractice(practice);
        history.push(config_1.WIZARD_URL);
    };
    (0, react_1.useEffect)(() => {
        return () => {
            setPracticesList(null);
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "practice-content", children: [(0, jsx_runtime_1.jsx)("p", { className: "practice", children: "Select Your Practice" }), (0, jsx_runtime_1.jsx)(components_1.PracticeSearchInput, { searchPromptText: "SEARCH AGAIN", withBackButton: true }), (0, jsx_runtime_1.jsx)("div", { className: "practice-result", children: !loading && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [practicesList ? ((0, jsx_runtime_1.jsx)(components_1.Card, { practice: practicesList, onPracticeSelection: handlePracticeSelection })) : ((0, jsx_runtime_1.jsx)("p", { className: "found-result", children: "We found 0 results." })), (0, jsx_runtime_1.jsx)("p", { className: "cannot-found", children: "Can't find your provider?" }), (0, jsx_runtime_1.jsx)("p", { className: "cannot-found-content", children: "Alphaeon Credit will happily contact your doctor about enrolling in the program." })] })) })] }) }));
}
function mapStateToProps(state) {
    return {
        practicesList: reducer_1.selectors.practicesList(state),
        loading: lp_redux_api_1.selectors.isLoading(state, 'PRACTICE_DATA'),
    };
}
const mapDispatchToProps = {
    setPractice: actions.setPractice,
    setPracticesList: actions.setPracticesList,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(Practice);
