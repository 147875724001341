"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
function InputWithToolTip({ input, tooltips: ToolTips, ...rest }) {
    const [focus, setFocus] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(lp_components_1.Input, { onFocus: () => {
                    setFocus(true);
                }, ...rest }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: input.name, focused: focus, children: (val) => {
                    if (val || focus) {
                        return (0, jsx_runtime_1.jsx)(ToolTips, {});
                    }
                    return null;
                } })] }));
}
exports.default = InputWithToolTip;
