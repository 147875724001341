"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const lp_components_1 = require("lp-components");
const config_1 = require("config");
const lodash_1 = require("lodash");
function Pagination({ handlePageChange, data, maxPageNumber, }) {
    const [currentPage, setCurrentPage] = (0, react_1.useState)(1);
    (0, react_1.useEffect)(() => {
        const indexOfLastData = currentPage * config_1.PAGINATION_PAGE_SIZE;
        const indexOfFirstData = indexOfLastData - config_1.PAGINATION_PAGE_SIZE;
        const paginatedData = data.slice(indexOfFirstData, indexOfLastData);
        handlePageChange(paginatedData);
    }, [currentPage, data]);
    (0, react_1.useEffect)(() => {
        if (maxPageNumber) {
            setCurrentPage(1);
        }
    }, [maxPageNumber]);
    const max = maxPageNumber
        ? maxPageNumber
        : (0, lodash_1.isNil)(data)
            ? 1
            : Math.ceil(data.length / config_1.PAGINATION_PAGE_SIZE);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(lp_components_1.Paginator, { alwaysShow: true, value: currentPage, max: max, onChange: (page) => setCurrentPage(page) }) }));
}
exports.default = Pagination;
