"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const redux_form_1 = require("redux-form");
const config_1 = require("config");
const react_redux_1 = require("react-redux");
function TransactionFilterForm({ onSubmit, filterOption, searchField, }) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form", children: (0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: onSubmit, children: (0, jsx_runtime_1.jsxs)("div", { className: "transaction-filter", children: [(0, jsx_runtime_1.jsx)("div", { className: "checkbox-group transaction-filter--item", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "filterOption", label: false, component: lp_components_1.RadioGroup, options: config_1.FILTER_OPTIONS, onChange: (event) => {
                                onSubmit({
                                    searchField,
                                    filterOption: event,
                                });
                            } }) }), (0, jsx_runtime_1.jsx)("div", { className: "transaction-filter--item", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field
                        // label={false}
                        , { 
                            // label={false}
                            component: lp_components_1.Input, name: "searchField", onChange: (_event, newSearchField) => {
                                onSubmit({
                                    searchField: newSearchField,
                                    filterOption,
                                });
                            }, placeholder: filterOption === config_1.TRANSACTION_FILTERS.CUSTOMER_NAME
                                ? 'First and Last Name'
                                : 'Account/Loan Number' }) })] }) }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    constraints: {},
}), (0, redux_form_1.formValues)('filterOption'), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(TransactionFilterForm);
