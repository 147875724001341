"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const reducer_1 = require("../reducer");
function PrequalDeclineModal({ onContinue, practice, }) {
    return ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { hideCloseButton: true, onClose: onContinue, overlayClassName: "modal-fade-screen prequal-decline-modal-overlay", className: "modal-inner prequal-decline-modal modal", children: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("div", { className: "prequal-decline-modal__container", children: [(0, jsx_runtime_1.jsxs)("div", { className: "details", children: ["We were unable to pre-qualify you for an Alphaeon Credit Card.", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), "However, you have been pre-qualified for our Access Loan", practice && ` at ${practice.name}`, "."] }), (0, jsx_runtime_1.jsx)("div", { className: "button-block", children: (0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-warn-outline", onClick: onContinue, type: "button", children: "Continue" }) })] }) }) }));
}
function mapStateToProps(state) {
    return {
        practice: reducer_1.selectors.practice(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PrequalDeclineModal);
