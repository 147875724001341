"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/**
 * @component ApplyPreFill
 */
const ApplyPreFill = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("h2", { children: "Apply PreFill" }) }));
};
exports.default = ApplyPreFill;
