"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
//@ts-nocheck
//nocheck for now since there are some unused vars being saved for later
const react_1 = require("react");
const lp_components_1 = require("lp-components");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const reducer_1 = require("../reducer");
const config_1 = require("config");
function PrequalAcceptDeclineModal({ onClose, payload, practice, }) {
    const history = (0, react_router_dom_1.useHistory)();
    const [loading, setLoading] = (0, react_1.useState)(false);
    // Keeping this for later.
    // eslint-disable-next-line no-unused-vars
    const requestPrequalification = () => {
        //empty for now.
    };
    // Keeping this for later.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const reApplyForPreQualification = () => {
        setLoading(true);
        return requestPrequalification(payload).then((prequalification) => {
            const { applicationStatus, lenderNextStepUrl } = prequalification;
            setLoading(false);
            if (applicationStatus === 'prequalified') {
                history.push({
                    pathname: config_1.WIZARD_URL,
                    state: {
                        step: 'PRE-QUALIFICATION',
                    },
                });
            }
            else if (applicationStatus === 'prequalified_account_exists') {
                history.push(config_1.PRE_QUALIFICATION_ACCOUNT_EXISTS_URL);
            }
            else if (applicationStatus === 'pending_approval') {
                // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
                //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."
                history.push({
                    pathname: config_1.ACCESS_LOAN_URL,
                    state: {
                        lenderNextStepUrl,
                        applicationStatus,
                    },
                });
            }
            else {
                history.push(config_1.PRE_QUALIFICATION_DECLINED_URL);
            }
        });
    };
    return ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { onClose: onClose, overlayClassName: "modal-fade-screen prequal-decline-modal-overlay", className: "modal-inner prequal-decline-modal modal", children: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("div", { className: "prequal-decline-modal__container", children: [(0, jsx_runtime_1.jsxs)("div", { className: "details", children: ["We were unable to approve you for an Alphaeon Credit Card. You will receive an explanation letter in 7-10 business days.", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), "However, we were able to pre-qualify you for our Access Loan", practice && ` at ${practice.name}`, "."] }), (0, jsx_runtime_1.jsx)("div", { className: "button-block", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: "button-warn-outline", disabled: loading, submitting: loading, onClick: () => onClose(), children: "Continue" }) })] }) }) }));
}
function mapStateToProps(state) {
    return {
        personalInfo: reducer_1.selectors.personalInfo(state),
        practice: reducer_1.selectors.practice(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PrequalAcceptDeclineModal);
