"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
// import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
// import * as apiActions from 'api-actions'
const reducerKey = 'legal';
exports.reducerKey = reducerKey;
// const slice = 'root.legal'
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({
// [apiActions.fetchLegals]: setOnSuccess('legals'),
// [apiActions.fetchLegal]: setOnSuccess('legal'),
// [actions.clearLegal]: unsetState('legal'),
}, initialState);
exports.reducer = reducer;
// const select = selectorForSlice(slice)
const selectors = {
// legals: select('legals'),
// legal: select('legal'),
};
exports.selectors = selectors;
