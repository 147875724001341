"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
const config_1 = require("config");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const apiActions = __importStar(require("api-actions"));
const utils_1 = require("utils");
const jwt_decode_1 = require("jwt-decode");
const jsonwebtoken_1 = require("jsonwebtoken");
const lodash_1 = require("lodash");
const VerifySSN = ({ validateSSN }) => {
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const [SSNInput, setSSNInput] = (0, react_1.useState)('');
    const [consentChecked, setConsentChecked] = (0, react_1.useState)(false);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)('');
    const [submitting, setSubmitting] = (0, react_1.useState)(false);
    const [maxAttemptsReached, setMaxAttemptsReached] = (0, react_1.useState)(false);
    const query = (0, utils_1.parseQuery)(location.search);
    const { sessionToken, state } = query;
    let isTokenDecodable;
    try {
        const tempDecodedToken = (0, jwt_decode_1.jwtDecode)(sessionToken);
        if (tempDecodedToken.encodedReceipt) {
            isTokenDecodable = true;
        }
        else {
            isTokenDecodable = false;
        }
    }
    catch {
        isTokenDecodable = false;
    }
    if ((0, lodash_1.isNil)(sessionToken) || (0, lodash_1.isNil)(state) || !isTokenDecodable) {
        history.push(config_1.CREDIT_PORTAL_URL);
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    const decodedSessionToken = (0, jwt_decode_1.jwtDecode)(sessionToken);
    const { encodedReceipt, continue_uri, sub, iat, iss, ip } = decodedSessionToken;
    if (!decodedSessionToken || !encodedReceipt || !atob(encodedReceipt)) {
        const authorizationErrorPath = (0, utils_1.makeQueryPath)(config_1.PATH.AUTHORIZATION_ERROR, {
            [config_1.QUERY_KEY.ERROR_DESCRIPTION]: 'Invalid Receipt',
        });
        return (0, utils_1.forceRedirect)({ to: authorizationErrorPath });
    }
    const receiptInfoDecoded = JSON.parse(atob(encodedReceipt));
    const receiptId = receiptInfoDecoded.receipt_id;
    const payload = {
        iat,
        iss,
        ip,
        sub,
        state,
        encodedReceipt,
        continue_uri,
    };
    const token = (0, jsonwebtoken_1.sign)(payload, config_1.REACT_APP_CONSUMER_TOKEN_SECRET, {
        expiresIn: '60s',
    });
    const handleValidateSSN = async () => {
        setSubmitting(true);
        validateSSN(receiptId, 'receipt', 'ssn_last_four', SSNInput)
            .then((response) => {
            const { result, attemptsRemaining } = response;
            if (result % 2 === 0) {
                window.location.href = `${continue_uri}?state=${state}&session_token=${token}`;
            }
            else if (attemptsRemaining > 0) {
                setErrorMessage(`Incorrect SSN/ITIN, ${attemptsRemaining} attempts remaining.`);
                setSubmitting(false);
            }
            else {
                setErrorMessage('You’ve reached the max number of attempts, please contact 1-920-306-1794.');
                setMaxAttemptsReached(true);
                setSubmitting(false);
            }
        })
            .catch(() => {
            setErrorMessage('You’ve reached the max number of attempts, please contact 1-920-306-1794.');
            setMaxAttemptsReached(true);
            setSubmitting(false);
        });
    };
    const handleSubmit = () => {
        setErrorMessage('');
        handleValidateSSN();
    };
    const handleConsent = (e) => {
        if (e.target.checked === true) {
            setConsentChecked(true);
        }
        else {
            setConsentChecked(false);
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "verify-container", children: [(0, jsx_runtime_1.jsx)("h2", { children: "Please Confirm Your Identification" }), (0, jsx_runtime_1.jsx)("h4", { className: "verify-instructions", children: "Enter the last four digits of your SSN/ITIN to review your receipt." }), (0, jsx_runtime_1.jsxs)("div", { className: "verify-ssn-input", children: [(0, jsx_runtime_1.jsx)("h5", { children: "CARDHOLDER LAST 4 SSN / ITIN" }), (0, jsx_runtime_1.jsxs)("div", { className: "input-and-message", children: [(0, jsx_runtime_1.jsx)(components_1.CodeInput, { setInputValue: setSSNInput }), (0, jsx_runtime_1.jsx)("div", { className: "verify-message", children: errorMessage })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "verify-consent", children: (0, jsx_runtime_1.jsxs)("label", { htmlFor: "verify-checkbox", children: [(0, jsx_runtime_1.jsx)("input", { onChange: handleConsent, type: "checkbox", name: "verify-checkbox", id: "verify-checkbox" }), (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { target: "_blank", rel: "noopener noreferrer", to: (0, config_1.makePath)(config_1.PATH.LEGAL, config_1.PATH.CONSENT_ELECTRONIC_DISCLOSURES), className: "agree-link", children: "Consent to Electronic Disclosures" }), ", which allows Alphaeon and Comenity Capital Bank to provide you disclosures electronically."] })] }) }), (0, jsx_runtime_1.jsx)("div", { className: "verify-submit", children: (0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-warn-outline", type: "submit", onClick: handleSubmit, submitting: submitting, pristine: SSNInput.length < 4 ||
                        submitting ||
                        !consentChecked ||
                        maxAttemptsReached, children: "SUBMIT" }) })] }));
};
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {
    validateSSN: apiActions.validateSSN,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(VerifySSN);
