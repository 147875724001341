"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const utils_1 = require("utils");
const react_cookie_1 = require("react-cookie");
const react_2 = require("@coreui/react");
const jwt_decode_1 = require("jwt-decode");
const portal_api_1 = require("portal-api");
function Home({}) {
    const successOpenCaseIframeRef = (0, react_1.useRef)(null);
    const bdTasksIframeRef = (0, react_1.useRef)(null);
    const { search } = (0, react_router_dom_1.useLocation)();
    const [cookies] = (0, react_cookie_1.useCookies)(['portalUser']);
    const { data: home } = (0, portal_api_1.useGetInternalPortalHomeQuery)(undefined);
    const query = (0, utils_1.parseQuery)(search);
    const creds = (0, jwt_decode_1.jwtDecode)(cookies.portalUser.credential);
    const userEmail = query['email'] || creds.email;
    const sendMessageToIframe = (iframeRef, filters) => {
        const filter_update = JSON.stringify({
            type: 'look:filters:update',
            filters: filters,
        });
        const my_request = JSON.stringify({
            type: 'look:run',
        });
        if (iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(filter_update, 'https://alphaeoncredit.cloud.looker.com');
            iframeRef.current.contentWindow.postMessage(my_request, 'https://alphaeoncredit.cloud.looker.com');
        }
    };
    const addEventListenerToIframe = () => {
        window.addEventListener('message', function (message) {
            const successOpenCaseIframe = document.getElementById('success-open-cases');
            const bdTasksIframe = document.getElementById('bd-tasks');
            if (message.origin === 'https://alphaeoncredit.cloud.looker.com') {
                if (message.source === successOpenCaseIframe?.contentWindow) {
                    const event = JSON.parse(message.data);
                    if (event.type == 'look:ready' || event.type == 'page:changed') {
                        sendMessageToIframe(successOpenCaseIframeRef, {
                            'salesforce_user_cases.email': userEmail,
                        });
                        sendMessageToIframe(bdTasksIframeRef, {
                            'salesforce_user_tasks.email': userEmail,
                        });
                    }
                }
                else if (message.source === bdTasksIframe?.contentWindow) {
                    const event = JSON.parse(message.data);
                    if (event.type == 'look:ready' || event.type == 'page:changed') {
                        sendMessageToIframe(successOpenCaseIframeRef, {
                            'salesforce_user_cases.email': userEmail,
                        });
                        sendMessageToIframe(bdTasksIframeRef, {
                            'salesforce_user_tasks.email': userEmail,
                        });
                    }
                }
                else if (message.source === bdTasksIframe?.contentWindow) {
                    const event = message.data;
                    if (event.type == 'look:ready') {
                        sendMessageToIframe(bdTasksIframeRef, {
                            'salesforce_user_tasks.email': userEmail,
                        });
                    }
                }
            }
        });
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "main-content-container home", children: [(0, jsx_runtime_1.jsx)(react_2.CCard, { style: { width: '50%' }, children: (0, jsx_runtime_1.jsxs)(react_2.CCardBody, { children: [(0, jsx_runtime_1.jsxs)(react_2.CCardTitle, { children: [' ', "Welcome ", creds.name, ", to the Internal Portal!"] }), (0, jsx_runtime_1.jsx)(react_2.CCardText, { children: "Here you will find some useful information and functionality to help you with your day to day tasks. We got this!" })] }) }), (0, jsx_runtime_1.jsxs)("div", { className: "portal-home-content", children: [(0, jsx_runtime_1.jsx)("div", { className: "looker-iframe", children: (0, jsx_runtime_1.jsx)(react_2.CCard, { children: (0, jsx_runtime_1.jsx)("iframe", { className: "", title: "Client Success - Open Cases", width: "1000px", height: "500", id: "success-open-cases", src: home?.lookerLooks?.successCases
                                        ? home?.lookerLooks
                                            ?.successCases /*+ `&embed_domain=${window.location.origin}`*/
                                        : undefined, onLoad: addEventListenerToIframe, style: { display: 'block', position: 'relative' }, ref: successOpenCaseIframeRef }) }) }), (0, jsx_runtime_1.jsx)("div", { className: "looker-iframe", children: (0, jsx_runtime_1.jsx)(react_2.CCard, { children: (0, jsx_runtime_1.jsx)("iframe", { className: "", title: "BD Tasks", width: "1000px", height: "500", id: "bd-tasks", src: home?.lookerLooks?.bdTasks
                                        ? home?.lookerLooks
                                            ?.bdTasks /*+  `&embed_domain=${window.location.origin}`*/
                                        : undefined, onLoad: addEventListenerToIframe, style: { display: 'block', position: 'relative' }, ref: bdTasksIframeRef }) }) })] })] }) }));
}
exports.default = Home;
