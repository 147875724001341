"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAFF = exports.AFF_ABBREV = void 0;
const date_fns_1 = require("date-fns");
const func_1 = require("./func");
const _YES = 'Y';
const _NO = 'N';
const _PAYFREQ = 'T';
const _WI = 'WI';
exports.AFF_ABBREV = { _YES, _NO, _PAYFREQ, _WI };
// Func validate form
/**
 * @name validateAFF
 * @param values
 * @returns errorsType | undefined
 */
const validateAFF = (values) => {
    if (values) {
        const errors = {};
        // Banking Fields
        if (!values.account_aba_number)
            errors.account_aba_number = 'Enter a routing number';
        if (!values.account_number)
            errors.account_number = 'Enter an account number';
        // Terms Agreement Checkboxes (need both in case a checkbox is checked then unchecked)
        if (!values.termsAgreedTo0)
            errors.termsAgreedTo0 = 'Please accept the terms';
        if (values.termsAgreedTo0 && values.termsAgreedTo0.length === 0)
            errors.termsAgreedTo0 = 'Please accept the terms';
        /**
         * PAY & DATES
         * ref: https://affapiv2.docs.apiary.io/#introduction/special-validation-rules
         *  */
        const payFreq = values.income_frequency;
        const payLast = values.income_pay_last;
        const payNext = values.income_pay_next;
        // Pay Frequency
        if (!payFreq || payFreq === '')
            errors.income_frequency = 'Select payment frequency';
        // Pay Next
        if (!payNext) {
            errors.income_pay_next = 'Enter a valid date';
            return errors;
        }
        if (payNext) {
            if (String(payNext).includes('_')) {
                errors.income_pay_next = 'Finish entering date';
                return errors;
            }
            const tmpDate = (0, date_fns_1.parse)(payNext, 'MM/dd/yyyy', new Date());
            const tmpTime = String(new Date()).split(' GMT')[0].slice(-8);
            const tmpDate2 = String(tmpDate).replace('00:00:00', tmpTime);
            const dateObj = new Date(tmpDate2);
            if (!(0, date_fns_1.parseISO)(payNext)) {
                errors.income_pay_next = ' Invalid date';
            }
            else {
                if (!(0, date_fns_1.isValid)(dateObj)) {
                    errors.income_pay_next = 'Date is not valid';
                }
                else {
                    if (!(0, date_fns_1.isFuture)(dateObj)) {
                        errors.income_pay_next = 'Date is in the past';
                    }
                    else {
                        // Check Pay Frequency
                        const payFreqErr = (0, func_1.checkPayFreq)(payFreq, dateObj);
                        if (payFreqErr) {
                            errors.income_pay_next = payFreqErr;
                        }
                    }
                }
            }
        }
        // Pay Last
        if (payFreq === _PAYFREQ) {
            if (!payLast)
                errors.income_pay_last = 'Enter a valid date';
            if (String(payLast).includes('_')) {
                errors.income_pay_last = 'Finish entering date';
                return errors;
            }
            const dateObj = (0, date_fns_1.parse)(payLast, 'MM/dd/yyyy', new Date());
            if (!(0, date_fns_1.parseISO)(payLast)) {
                errors.income_pay_last = ' Invalid date';
            }
            else {
                if (!(0, date_fns_1.isValid)(dateObj)) {
                    errors.income_pay_last = 'Date is not valid';
                }
                else {
                    const comparePayErr = (0, func_1.comparePayFreq)(payLast, payNext);
                    if (comparePayErr) {
                        errors.income_pay_next = comparePayErr;
                    }
                }
            }
        }
        if (values?.stateCode === _WI) {
            if (!values.married)
                errors.married = 'Select an option';
        }
        /** WISCONSIN MARRIED */
        if (values.married === _YES) {
            if (!values.spouse_live_with) {
                errors.spouse_live_with = 'Select an option';
            }
            if (values.spouse_live_with === _NO) {
                if (!values.address_spouse_line1) {
                    errors.address_spouse_line1 = 'Required';
                }
                if (!values.spouse_name) {
                    errors.spouse_name = 'Required';
                }
                if (!values.address_spouse_city) {
                    errors.address_spouse_city = 'Required';
                }
                if (!values.address_spouse_state) {
                    errors.address_spouse_state = 'Required';
                }
                if (!values.address_spouse_zip) {
                    errors.address_spouse_zip = 'Required';
                }
            }
        }
        if (Object.keys(errors).length) {
            return errors;
        }
        else {
            return undefined;
        }
    }
};
exports.validateAFF = validateAFF;
