"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.api = void 0;
const react_1 = require("@reduxjs/toolkit/query/react");
const config_1 = require("config");
const utils_1 = require("utils");
const baseQuery = (0, react_1.fetchBaseQuery)({
    baseUrl: config_1.REACT_APP_API_URL,
});
const baseQueryWithCamelize = (0, utils_1.camelizeResponse)(baseQuery);
exports.api = (0, react_1.createApi)({
    reducerPath: 'consumerPortalApi',
    baseQuery: baseQueryWithCamelize,
    endpoints: () => ({}),
    keepUnusedDataFor: 0,
});
_a = exports.api;
