"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const query_string_1 = require("query-string");
const humps_1 = require("humps");
// Decamelize and stringify query params object
function stringifyQuery(query) {
    return (0, query_string_1.stringify)((0, humps_1.decamelizeKeys)(query), { skipNull: true });
}
function makeQueryPath(path, query) {
    if (!query)
        return path;
    return path + '?' + stringifyQuery(query);
}
exports.default = makeQueryPath;
