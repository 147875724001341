"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
const lp_redux_utils_1 = require("lp-redux-utils");
const actions = __importStar(require("./global-actions"));
const reducerKey = 'global';
exports.reducerKey = reducerKey;
const slice = 'root.global';
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({
    [actions.recordApplicationBuildId.toString()]: (0, lp_redux_utils_1.setState)('applicationBuildId'),
}, initialState);
exports.reducer = reducer;
const select = (0, lp_redux_utils_1.selectorForSlice)(slice);
const selectors = {
    applicationBuildId: select('applicationBuildId'),
};
exports.selectors = selectors;
