"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
function InputCheckbox({ input, meta, rest, }) {
    const cleanRest = {
        ...rest,
        label: undefined, // Removed or console error
    };
    return ((0, jsx_runtime_1.jsxs)("span", { children: [(meta.error || meta.submitError) && meta.touched && ((0, jsx_runtime_1.jsx)("span", { className: "error-message", children: meta.error || meta.submitError })), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("input", { ...input, ...cleanRest, type: "checkbox" })] }));
}
exports.default = InputCheckbox;
