"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const routerSelectors = __importStar(require("connected-react-router"));
const components_1 = require("components");
const utils_1 = require("utils");
const react_router_dom_1 = require("react-router-dom");
function Layout({ children }) {
    const location = (0, react_router_dom_1.useLocation)();
    // Scroll to top of page when route changes
    (0, react_1.useEffect)(() => {
        (0, utils_1.scrollToTop)();
    }, [location.pathname]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "credit-portal", children: [(0, jsx_runtime_1.jsx)(components_1.FlashMessageContainer, {}), (0, jsx_runtime_1.jsx)(components_1.SkipNavLink, { targetId: "main-content", children: "Skip to main content" }), (0, jsx_runtime_1.jsx)(components_1.Header, {}), (0, jsx_runtime_1.jsx)("main", { id: "main-content", children: children }), (0, jsx_runtime_1.jsx)(components_1.NetworkErrorReporter, {}), (0, jsx_runtime_1.jsx)(components_1.Footer, {})] }));
}
function mapStateToProps(state) {
    return {
        pathname: routerSelectors.getLocation(state).pathname,
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(Layout);
