"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const redux_form_1 = require("redux-form");
const utils_1 = require("utils");
const components_1 = require("components");
function PushApplicationForm({ handleSubmit, submitting, reset, }) {
    (0, react_1.useEffect)(() => {
        reset();
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form m-t-50 push-app__form", children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "firstName", component: lp_components_1.Input, placeholder: "First Name", label: "First Name", required: true }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "lastName", component: lp_components_1.Input, placeholder: "Last Name", required: true }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "primaryPhone", placeholder: "Mobile Number", label: "Mobile Number", component: components_1.InputTip, inputType: lp_components_1.MaskedInput, required: true, parse: utils_1.removeNonDigits, maskOptions: {
                        numericOnly: true,
                        blocks: [0, 3, 3, 4],
                        delimiters: ['(', ') ', '-'],
                    } }), (0, jsx_runtime_1.jsx)("div", { className: "date-of-birth", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: components_1.InputTip, inputType: lp_components_1.MaskedInput, name: "dateOfBirth", placeholder: "MM/DD/YYYY", required: true, maskOptions: {
                            date: true,
                            datePattern: ['m', 'd', 'Y'],
                        } }) }), (0, jsx_runtime_1.jsx)("div", { className: "start-application__push_app", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: 'button-warn-outline', submitting: submitting, children: "SEND APPLICATION" }) })] }) }));
}
function beforeSubmit(formValues) {
    const dateOfBirth = (0, utils_1.parseUSDayMonthYearDate)(formValues.dateOfBirth);
    if (!(0, utils_1.validDateOfBirth)(dateOfBirth)) {
        throw new redux_form_1.SubmissionError({
            dateOfBirth: 'Date of birth year must be after 1900 and applicant must be at least 18 years old',
        });
    }
    return {
        ...formValues,
        dateOfBirth: (0, utils_1.formatISODate)(dateOfBirth),
    };
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    beforeSubmit,
    name: 'pushApplicationForm',
    constraints: {
        firstName: { presence: true },
        lastName: { presence: true },
        primaryPhone: {
            presence: { message: "^Mobile Number can't be blank" },
            length: { is: 10 },
        },
        dateOfBirth: { presence: true, length: { is: 10 } },
    },
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PushApplicationForm);
