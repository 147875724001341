"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
const redux_1 = require("redux");
const fp_1 = require("lodash/fp");
const lp_redux_api_1 = require("lp-redux-api");
const lp_redux_utils_1 = require("lp-redux-utils");
const actions = __importStar(require("./actions"));
const apiActions = __importStar(require("api-actions"));
const reducerKey = 'applicants';
exports.reducerKey = reducerKey;
const slice = 'root.creditPortal.applicants';
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({
    [actions.clearApplicationEnvironment.toString()]: (0, lp_redux_utils_1.unsetState)('applicationEnvironment'),
    [actions.clearApplicant.toString()]: (state) => {
        return (0, redux_1.compose)((0, fp_1.unset)('creditApplication'), (0, fp_1.unset)('personalInfo'), (0, fp_1.unset)('practice'), (0, fp_1.unset)('prequalifications'))(state);
    },
    [apiActions.getCreditApplicationByToken]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data }, }) => {
        return (0, redux_1.compose)((0, fp_1.set)('creditApplication', data.creditApplication), (0, fp_1.set)('practice', data.location), (0, fp_1.set)('personalInfo', data.applicant))(state);
    }),
    [apiActions.getCreditApplicationByTokenValidate]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data }, }) => {
        return (0, redux_1.compose)((0, fp_1.set)('creditApplication', data.creditApplication), (0, fp_1.set)('practice', data.location), (0, fp_1.set)('personalInfo', data.applicant))(state);
    }),
    [apiActions.newCreditApplication]: (0, lp_redux_api_1.setOnSuccess)('applicationEnvironment'),
    [apiActions.createCreditApplicationIdentityVerification]: (0, lp_redux_api_1.setOnSuccess)('identityVerification'),
}, initialState);
exports.reducer = reducer;
const select = (0, lp_redux_utils_1.selectorForSlice)(slice);
const selectors = {
    applicationEnvironment: select('applicationEnvironment'),
    creditApplication: select('creditApplication'),
    personalInfo: select('personalInfo'),
    practice: select('practice'),
    prequalifications: select('prequalifications'),
    identityVerification: select('identityVerification'),
};
exports.selectors = selectors;
