"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const redux_flash_1 = require("redux-flash");
const utils_1 = require("utils");
function NetworkErrorReporter({ flashErrorMessage, flashSuccessMessage, }) {
    (0, react_1.useEffect)(() => {
        window.addEventListener('online', () => flashSuccessMessage('Network is available', { push: true, timeout: 5000 }));
        window.addEventListener('offline', () => {
            const errMsg = "Network is unavailable. Are you sure you're connected to the network?";
            flashErrorMessage(errMsg);
            (0, utils_1.reportError)('flashError', {
                componentName: 'NetworkErrorReporter',
                errType: 'network',
                errURL: window.location.pathname,
                errMsg: errMsg,
            });
        });
    }, []);
    // This page renders no content but exists merely to set up the network status
    // listeners...
    return null;
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {
    flashErrorMessage: redux_flash_1.flashErrorMessage,
    flashSuccessMessage: redux_flash_1.flashSuccessMessage,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(NetworkErrorReporter);
