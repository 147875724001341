"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
const redux_1 = require("redux");
const fp_1 = require("lodash/fp");
const lp_redux_utils_1 = require("lp-redux-utils");
const lp_redux_api_1 = require("lp-redux-api");
const actions = __importStar(require("./actions"));
const apiActions = __importStar(require("../../apiActions"));
const reducerKey = 'creditApplication';
exports.reducerKey = reducerKey;
const slice = 'root.creditPortal.creditApplication';
const initialState = {
    proveApp: null,
    proveVerify: null,
};
const reducer = (0, redux_actions_1.handleActions)({
    [actions.clearProveApp.toString()]: (0, lp_redux_utils_1.unsetState)('proveApp'),
    [actions.clearProveVerify.toString()]: (0, lp_redux_utils_1.unsetState)('proveVerify'),
    [actions.setProveApp.toString()]: (0, lp_redux_utils_1.setState)('proveApp'),
    [actions.setProveVerify.toString()]: (0, lp_redux_utils_1.setState)('proveVerify'),
    [actions.clearApplicationEnvironment.toString()]: (0, lp_redux_utils_1.unsetState)('applicationEnvironment'),
    [actions.clearPractice.toString()]: (0, lp_redux_utils_1.unsetState)('practice'),
    [actions.clearPracticeLocations.toString()]: (0, lp_redux_utils_1.unsetState)('practiceLocations'),
    [actions.setPractice.toString()]: (0, lp_redux_utils_1.setState)('practice'),
    [actions.setPersonalInfo.toString()]: (0, lp_redux_utils_1.setState)('personalInfo'),
    [actions.setPracticesList.toString()]: (0, lp_redux_utils_1.setState)('practicesList'),
    [actions.setPracticeLocations.toString()]: (0, lp_redux_utils_1.setState)('practiceLocations'),
    [actions.setPrequalification.toString()]: (0, lp_redux_utils_1.setState)('prequalification'),
    [actions.setLenderReferenceId.toString()]: (0, lp_redux_utils_1.setState)('lenderReferenceId'),
    [actions.setLenderName.toString()]: (0, lp_redux_utils_1.setState)('lenderName'),
    [actions.setCreditApplication.toString()]: (0, lp_redux_utils_1.setState)('creditApplication'),
    [actions.clearPersonalInfo.toString()]: (0, lp_redux_utils_1.unsetState)('personalInfo'),
    [actions.setApprovedApplication.toString()]: (0, lp_redux_utils_1.setState)('approvedApplication'),
    [actions.setPendingDecisionDetails.toString()]: (0, lp_redux_utils_1.setState)('pendingDecisionDetails'),
    [apiActions.applyAfterPrequalification]: (0, lp_redux_api_1.setOnSuccess)('approvedApplication'),
    [apiActions.submitFullApplication]: (0, lp_redux_api_1.setOnSuccess)('approvedApplication'),
    [apiActions.getPracticeLocations]: (0, lp_redux_api_1.setOnSuccess)('practiceLocations'),
    [apiActions.getCreditApplicationsByStatus]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data }, }) => {
        return (0, redux_1.compose)((0, fp_1.set)('prequalifications', data.prequalifications), (0, fp_1.set)('personalInfo', data.applicant))(state);
    }),
    [apiActions.newCreditApplication]: (0, lp_redux_api_1.setOnSuccess)('applicationEnvironment'),
    [apiActions.prequalifyWithNextLender]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data }, }) => {
        return (0, redux_1.compose)((0, fp_1.set)('lenderReferenceId', data.lenderRequestReferenceId))(state);
    }),
    [apiActions.requestPrequalification]: (0, lp_redux_api_1.setOnSuccess)('prequalification'),
    [apiActions.searchForPractices]: (0, lp_redux_api_1.setOnResponse)('practicesList', 'practiceSearchError'),
}, initialState);
exports.reducer = reducer;
const select = (0, lp_redux_utils_1.selectorForSlice)(slice);
const selectors = {
    proveApp: select('proveApp'),
    proveVerify: select('proveVerify'),
    practice: select('practice'),
    preQualificationData: select('preQualificationData'),
    approvedApplication: select('approvedApplication'),
    applicationEnvironment: select('applicationEnvironment'),
    creditApplication: select('creditApplication'),
    personalInfo: select('personalInfo'),
    practicesList: select('practicesList'),
    practiceSearchError: select('practiceSearchError'),
    prequalification: select('prequalification'),
    prequalifications: select('prequalifications'),
    practiceLocations: select('practiceLocations'),
    lenderReferenceId: select('lenderReferenceId'),
    lenderName: select('lenderName'),
    pendingDecisionDetails: select('pendingDecisionDetails'),
};
exports.selectors = selectors;
