"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const date_fns_1 = require("date-fns");
function parseAndCompareAsc(dateString1, dateString2) {
    if ((0, lodash_1.isNil)(dateString1))
        return 1;
    if ((0, lodash_1.isNil)(dateString2))
        return -1;
    const date1 = (0, date_fns_1.parseISO)(dateString1);
    const date2 = (0, date_fns_1.parseISO)(dateString2);
    return (0, date_fns_1.compareAsc)(date1, date2);
}
exports.default = parseAndCompareAsc;
