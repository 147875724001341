"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const utils_1 = require("utils");
const redux_form_1 = require("redux-form");
function RefundRequestForm({ handleSubmit, correctAmountProvided, handleCancel, submitting, }) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form  m-t-50", children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)("div", { className: "refund-amount", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: components_1.InputTip, inputType: lp_components_1.MaskedInput, name: "amount", placeholder: "Refund Amount", required: true, requiredIndicator: " *", parse: utils_1.toAmount, maskOptions: {
                            prefix: '$',
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                        } }) }), (0, jsx_runtime_1.jsx)("div", { className: "", children: (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "correctAmountProvided", component: lp_components_1.Checkbox, label: false }), "I confirm that this is the correct account and amount to be refunded."] }) }), (0, jsx_runtime_1.jsxs)("div", { className: "start-application__form--actions m-t-50 m-b-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { disabled: !correctAmountProvided || submitting, submitting: submitting, className: `button-warn ${!correctAmountProvided ? `warn-disabled` : ``}`, children: "SUBMIT REFUND" }) }), (0, jsx_runtime_1.jsx)("div", { className: "cancel", children: (0, jsx_runtime_1.jsx)("div", { role: "presentation", onClick: () => handleCancel(), children: "CANCEL" }) })] })] }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'refundRequestForm',
    constraints: {
        plan: { presence: true },
        amount: { presence: true },
    },
    submitFilters: { reject: ['correctAmountProvided'] },
}), (0, redux_form_1.formValues)('correctAmountProvided'), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(RefundRequestForm);
