"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const applicationServerApiActions = __importStar(require("application-server-api-actions"));
const global_reducer_1 = require("global-reducer");
const config_1 = require("config");
const hooks_1 = require("hooks");
const utils_1 = require("utils");
function VersionChangeReporter({ applicationBuildId, redirectURL, getApplicationBuildId, }) {
    const [displayVersionModal, setDisplayVersionModal] = (0, react_1.useState)(false);
    const reloadApplication = () => {
        (0, utils_1.forceRedirect)({ to: redirectURL });
    };
    const determineIfBuildIdChanged = (0, react_1.useCallback)(async () => {
        if (!applicationBuildId)
            return;
        const latestBuildId = await (0, utils_1.fetchApplicationBuildId)();
        if (!(0, lodash_1.isNil)(latestBuildId) && latestBuildId !== applicationBuildId) {
            setDisplayVersionModal(true);
        }
    }, [applicationBuildId]);
    (0, react_1.useEffect)(() => {
        getApplicationBuildId();
    }, []);
    (0, hooks_1.useInterval)(determineIfBuildIdChanged, {
        interval: config_1.REACT_APP_BUILDID_FETCH_INTERVAL,
    });
    return (displayVersionModal && ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { className: "alert-modal", onClose: reloadApplication, preventClose: true, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("header", { children: (0, jsx_runtime_1.jsx)("h1", { children: "System Upgrade/Updated" }) }), (0, jsx_runtime_1.jsx)("p", { children: "We apologize for the inconvenience, but we just released some updates to the dashboard that will require a page reload to take effect. Please click below to reload your dashboard." }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-primary-outline", onClick: reloadApplication, children: "Continue" })] }) })));
}
function mapStateToProps(state) {
    return {
        applicationBuildId: global_reducer_1.selectors.applicationBuildId(state),
    };
}
const mapDispatchToProps = {
    getApplicationBuildId: applicationServerApiActions.getApplicationBuildId,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(VersionChangeReporter);
