"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
function VoidSaleForm({ handleSubmit, cancelVoidSale, submitting, }) {
    return ((0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: handleSubmit, children: (0, jsx_runtime_1.jsx)("div", { className: "account-summary summary", children: (0, jsx_runtime_1.jsxs)("div", { className: "summary__actions m-t-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { disabled: submitting, submitting: submitting, className: `button-warn`, children: "CONFIRM" }) }), (0, jsx_runtime_1.jsx)("div", { className: "cancel", children: (0, jsx_runtime_1.jsx)("div", { onClick: () => cancelVoidSale(), tabIndex: 0, role: "button", onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    cancelVoidSale();
                                }
                            }, children: "CANCEL" }) })] }) }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'voidSaleForm',
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(VoidSaleForm);
