"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function contactFormInitialValues({ email, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
sms = undefined, }) {
    // Favor SMS over email for the initial value
    // For now, disable considering SMS.
    // const patientContactOptions = sms ?? email
    const patientContactOptions = email;
    return {
        patientContactOptions,
    };
}
exports.default = contactFormInitialValues;
