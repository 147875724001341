"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const lodash_1 = require("lodash");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const merchant_portal_components_1 = require("merchant-portal-components");
function ContactPatientForm({ handleSubmit, handleCancel, patientContactOptions = '', withReference = false, submitting, }) {
    return ((0, jsx_runtime_1.jsxs)("form", { noValidate: true, name: "contactPatientForm", onSubmit: handleSubmit, style: { width: '100%' }, children: [withReference && ((0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "userReferenceId", component: lp_components_1.Input, label: "Reference ID (Optional)", placeholder: "Reference ID" })), (0, jsx_runtime_1.jsxs)("div", { className: "radio-group", children: [(0, jsx_runtime_1.jsx)("div", { className: "info-contain", children: "Please select the patient's preferred method of contact, and confirm the contact information." }), (0, jsx_runtime_1.jsx)("div", { className: "radio-group__share m-t-30", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: merchant_portal_components_1.ContactPatientRadioGroup, name: "patientContactOptions", label: false, options: allPatientContactOptions(patientContactOptions) }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "button-block", children: [(0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { disabled: submitting, submitting: submitting, className: "button-warn m-t-30", children: "Send To Patient" }), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-warn-outline m-t-30", onClick: () => handleCancel(), children: "Cancel" })] })] }));
}
function beforeSubmit(formValues) {
    const { patientContactOptions } = formValues;
    if ((0, lodash_1.isEmpty)(patientContactOptions)) {
        throw new redux_form_1.SubmissionError({
            _error: 'Selected contact option must have a value specified',
        });
    }
    const contactMethod = /^\d+$/.test(patientContactOptions) ? 'sms' : 'email';
    return {
        ...(0, lodash_1.omit)(formValues, 'patientContactOptions'),
        [contactMethod]: patientContactOptions,
    };
}
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    beforeSubmit,
    name: 'contactPatientForm',
}), (0, redux_form_1.formValues)('patientContactOptions'))(ContactPatientForm);
function allPatientContactOptions(patientContactOptionValue) {
    let sms, email;
    if (/^\d+$/.test(patientContactOptionValue)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sms = patientContactOptionValue;
    }
    else {
        email = patientContactOptionValue;
    }
    return {
        email,
    };
}
