"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const merchant_portal_components_1 = require("merchant-portal-components");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
const utils_1 = require("utils");
function Layout({ children }) {
    const location = (0, react_router_dom_1.useLocation)();
    // Scroll to top of page when route changes
    (0, react_1.useEffect)(() => {
        (0, utils_1.scrollToTop)();
    }, [location.pathname]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "merchant-portal", children: [(0, jsx_runtime_1.jsx)(components_1.FlashMessageContainer, {}), (0, jsx_runtime_1.jsx)(merchant_portal_components_1.SiteInitializationHeader, {}), (0, jsx_runtime_1.jsx)("div", { className: "container m-b-50", children: children }), (0, jsx_runtime_1.jsx)(components_1.NetworkErrorReporter, {}), (0, jsx_runtime_1.jsx)(merchant_portal_components_1.SiteInitializationFooter, {})] }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(Layout);
