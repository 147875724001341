"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
const portal_api_1 = require("portal-api");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
function LocationSearch() {
    const [searchCriteria, setSearchCriteria] = (0, react_1.useState)('');
    const [practiceLocations, setPracticeLocations] = (0, react_1.useState)([]);
    const history = (0, react_router_dom_1.useHistory)();
    const handleSubmit = (item) => {
        if (item && item.locationId) {
            setSearchCriteria('');
            clearPracticeLocations();
            history.push({
                pathname: (0, config_1.makePath)(config_1.PATH.INTERNAL_PORTAL, config_1.PATH.LOCATIONS, '/', item.locationId.toString()),
            });
        }
    };
    const clearPracticeLocations = () => {
        setPracticeLocations([]);
    };
    const getPracticeLocations = (queryType, value) => {
        setSearchCriteria(value);
        if (value != null && value !== '') {
            refreshPracticeLocations();
        }
    };
    const { data: locationData, refetch: refreshPracticeLocations } = (0, portal_api_1.useGetLocationsSearchQuery)({
        searchCriteria: searchCriteria,
        limit: 5,
        fieldsToSearch: 'all',
    });
    (0, react_1.useEffect)(() => {
        if (locationData) {
            setPracticeLocations(locationData);
        }
    }, [locationData]);
    return ((0, jsx_runtime_1.jsx)(components_1.PracticeSearchForm, { handleSubmit: handleSubmit, clearPracticeLocations: clearPracticeLocations, practiceLocations: practiceLocations, setPracticeLocations: setPracticeLocations, getPracticeLocations: getPracticeLocations, useStyle: false, displayOptions: {
            showPrequalify: false,
            placeholder: 'Location Search',
        } }));
}
exports.default = LocationSearch;
