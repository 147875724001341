"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const query_string_1 = require("query-string");
const humps_1 = require("humps");
function parseQuery(search) {
    if (!search)
        return {};
    return (0, humps_1.camelizeKeys)((0, query_string_1.parse)(search));
}
exports.default = parseQuery;
