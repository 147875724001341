"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const lp_form_1 = require("lp-form");
const redux_form_1 = require("redux-form");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const utils_1 = require("utils");
const react_redux_1 = require("react-redux");
function LoanAdjustmentForm({ submitting, handleSubmit, handleCancelEdit, }) {
    return ((0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)("div", { className: "loan-adjustment-form", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { className: "loan-amount-input", component: lp_components_1.Input, inputType: lp_components_1.MaskedInput, name: "amount", placeholder: "Loan Amount", parse: utils_1.toAmount, maskOptions: {
                            prefix: '$',
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                        } }), (0, jsx_runtime_1.jsx)("div", { className: "date-selection", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.DateInput, name: "serviceDate", label: "Service Date", autoComplete: "off" }) }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "userReferenceId", component: lp_components_1.Input, label: "Reference ID (Optional)", placeholder: "Reference ID" })] }), (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { submitting: submitting, disabled: submitting, className: `button-warn-outline`, children: "SAVE INFORMATION" }), (0, jsx_runtime_1.jsx)(components_1.ButtonLink, { onClick: handleCancelEdit, className: "select-all button-link", children: "CANCEL EDIT" })] }));
}
function beforeSubmit(formValues) {
    const newDate = new Date();
    const today = new Date(newDate);
    today.setDate(newDate.getDate() - 1);
    const { serviceDate } = formValues;
    if (serviceDate < today) {
        throw new redux_form_1.SubmissionError({
            serviceDate: 'Service Date should be today at the earliest',
        });
    }
    return formValues;
}
function mapStateToProps() {
    return {};
}
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    beforeSubmit,
    name: 'loanAdjustmentForm',
    constraints: {
        amount: {
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 500,
            },
        },
    },
}), (0, redux_form_1.formValues)({ amount: 'amount', serviceDate: 'serviceDate' }), (0, react_redux_1.connect)(mapStateToProps))(LoanAdjustmentForm);
