"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const hooks_1 = require("hooks");
const config_1 = require("config");
const components_1 = require("components");
const api_1 = require("./utils/api");
const VerifyLink = () => {
    // Hooks
    const query = (0, hooks_1.useQuery)();
    const history = (0, react_router_dom_1.useHistory)();
    // State
    const [isAvailable, setIsAvailable] = (0, react_1.useState)(null); // Pure Service
    const [verifyFailed, setVerifyFailed] = (0, react_1.useState)(false); // Pure Verification
    /**
     * @name init
     * @desc Check if Pure is available
     */
    const init = async () => {
        const paramVFP = query.get('vfp');
        if (paramVFP) {
            const respJson = await (0, api_1.postProveAuthLinkClicked)({ vfp: paramVFP });
            const resp = await respJson?.json();
            let formUrl = '/credit-portal/apply/wizard';
            if (respJson?.status === 201 && resp.status === 'verified') {
                setIsAvailable(true);
                if (resp.location_id !== null) {
                    formUrl = (0, config_1.makeStoreUrl)(resp?.ads_store_number);
                }
                if (resp.credit_application_id !== null) {
                    formUrl = (0, config_1.makeStoreUrl)(resp?.ads_store_number);
                }
            }
            else {
                setVerifyFailed(true);
                history.push(formUrl); // Go to legacy form
            }
        }
        else {
            setIsAvailable(false);
        }
    };
    const handleClick = () => {
        window.close(); // close current tab
    };
    // Effects
    (0, react_1.useEffect)(() => {
        init();
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [verifyFailed && (0, jsx_runtime_1.jsx)(VerifyFailed, {}), isAvailable && (0, jsx_runtime_1.jsx)(VerifyComplete, { handleClick: handleClick }), isAvailable === false && (0, jsx_runtime_1.jsx)(VerifyUnavailable, {}), isAvailable === null && verifyFailed === false && ((0, jsx_runtime_1.jsx)("div", { className: "text-center", children: (0, jsx_runtime_1.jsx)(components_1.Spinner, {}) }))] }));
};
exports.default = VerifyLink;
const VerifyComplete = ({ handleClick }) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsxs)("h2", { className: "weight-700", children: ["Your Mobile Verification", (0, jsx_runtime_1.jsx)("br", {}), " Is Complete"] }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", { className: "margin-auto p-20 max-width-500", children: (0, jsx_runtime_1.jsx)("p", { children: "Please return to your original screen to complete your pre-qualification." }) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("button", { onClick: () => handleClick(), className: "button-primary button-warn-outline", children: "Close Current Tab" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(components_1.AnimationPhoneSuccess, {})] }));
};
const VerifyUnavailable = () => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsx)("h2", { className: "weight-700", children: "Mobile Verification Unavailable" }), (0, jsx_runtime_1.jsx)(components_1.AnimationFail, {}), (0, jsx_runtime_1.jsx)("div", { className: "p-20", children: (0, jsx_runtime_1.jsx)("p", { children: "Sorry, we are unable to verify your mobile number at this time. Please try again later." }) })] }));
};
const VerifyFailed = () => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsx)("h2", { className: "weight-700", children: "Verification Failed" }), (0, jsx_runtime_1.jsx)(components_1.AnimationFail, {}), (0, jsx_runtime_1.jsx)("div", { className: "p-20", children: (0, jsx_runtime_1.jsx)("p", { children: "Sorry, mobile verification failed. Please try again later." }) })] }));
};
