"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const utils_1 = require("utils");
const FEATURE_TO_LABEL = {
    supplementalFundingEnabled: 'Supplemental Funding',
    pushApplicationEnabled: 'OTA',
    digitalReceiptsEnabled: 'Digital Receipts',
};
function LocationFeatures({ location }) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.entries(FEATURE_TO_LABEL).map(([feature, label]) => ((0, jsx_runtime_1.jsx)("div", { className: "location-features", children: (0, jsx_runtime_1.jsxs)("div", { className: "location-feature", children: [(0, jsx_runtime_1.jsx)("span", { className: "lender-name", children: (0, utils_1.titleCase)(label) }), (0, jsx_runtime_1.jsx)(react_1.CFormSwitch, { className: "toggle-button", disabled: true, checked: location[feature] })] }) }, feature))) }));
}
exports.default = LocationFeatures;
