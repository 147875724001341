"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const utils_1 = require("utils");
const config_1 = require("config");
const react_router_dom_1 = require("react-router-dom");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const reducer_1 = require("../reducer");
const actions = __importStar(require("../actions"));
const redux_flash_1 = require("redux-flash");
function PushApplication({ getCreditApplicationByTokenValidate, creditApplication, setPersonalInfo, setPractice, practice, personalInfo, flashErrorMessage, }) {
    const { search } = (0, react_router_dom_1.useLocation)();
    const query = (0, utils_1.parseQuery)(search);
    const token = query[config_1.QUERY_KEY.TOKEN];
    const history = (0, react_router_dom_1.useHistory)();
    const redirectToStoreWizard = (creditApplication) => {
        if (config_1.REACT_APP_PURE_TEST_LIST &&
            typeof config_1.REACT_APP_PURE_TEST_LIST === 'string' &&
            config_1.REACT_APP_PURE_TEST_LIST.split(',').includes(personalInfo?.primaryPhone)) {
            history.push({
                pathname: '/credit-portal/prove',
                search: `?token=${token}&store=${practice.adsStoreNumber}`,
                state: {
                    personalInfo: personalInfo,
                    practice: practice,
                    creditApplication: creditApplication,
                },
            });
        }
        else {
            history.push({
                pathname: (0, config_1.makeStoreUrl)(practice.adsStoreNumber), // + "?token=" +token,
                search: '?token=' + token,
                state: {
                    practice: practice,
                },
            });
        }
    };
    (0, react_1.useEffect)(() => {
        getCreditApplicationByTokenValidate(token).catch((error) => {
            if (error?.response?.errorCode === 'no_credit_application_found') {
                (0, utils_1.reportError)('flashError', {
                    componentName: 'PushApplications',
                    funcName: 'getCreditApplicationByTokenValidate',
                    errType: 'token',
                    errURL: window.location.pathname,
                    errMsg: config_1.API_ERROR_MAP.no_credit_application_found_one_time,
                }, error);
                flashErrorMessage(config_1.API_ERROR_MAP.no_credit_application_found_one_time);
                history.push(config_1.CREDIT_PORTAL_URL);
            }
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (creditApplication && creditApplication.applicationId && practice) {
            setPractice(practice);
            setPersonalInfo(personalInfo);
            if (creditApplication.applicationStatus === 'created') {
                redirectToStoreWizard(creditApplication);
            }
            else {
                history.push(config_1.APPLICATION_FINDER_URL); // To  Do handle this
            }
        }
    }, [creditApplication, practice]);
    return (0, jsx_runtime_1.jsx)(components_1.Spinner, { centerPage: true });
}
function mapStateToProps(state) {
    return {
        creditApplication: reducer_1.selectors.creditApplication(state),
        practice: reducer_1.selectors.practice(state),
        personalInfo: reducer_1.selectors.personalInfo(state),
    };
}
const mapDispatchToProps = {
    getCreditApplicationByTokenValidate: apiActions.getCreditApplicationByTokenValidate,
    setPersonalInfo: actions.setPersonalInfo,
    setPractice: actions.setPractice,
    flashErrorMessage: redux_flash_1.flashErrorMessage,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PushApplication);
