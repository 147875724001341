"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const redux_form_1 = require("redux-form");
function ActivityFilterForm({ onSubmit, startDate, endDate, }) {
    const options = [
        { key: 'Approved', value: 'Approved' },
        { key: 'Declined', value: 'Declined' },
        { key: 'All', value: 'All' },
    ];
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form activity-filter-form", children: (0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: onSubmit, children: (0, jsx_runtime_1.jsxs)("div", { className: "activity-filter", children: [(0, jsx_runtime_1.jsx)("div", { className: "activity-filter--item", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.DateInput, name: "startDate", onChange: (_, newStartDate) => {
                                onSubmit({
                                    startDate: newStartDate,
                                    endDate,
                                });
                            } }) }), (0, jsx_runtime_1.jsx)("div", { className: "activity-filter--item", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.DateInput, name: "endDate", onChange: (_, newEndDate) => {
                                onSubmit({
                                    startDate,
                                    endDate: newEndDate,
                                });
                            } }) }), (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-group activity-filter--item", children: [(0, jsx_runtime_1.jsx)("label", { className: "label", htmlFor: "applicationStatus", children: "Application Status" }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { label: false, component: lp_components_1.CheckboxGroup, name: "applicationStatus", options: options })] })] }) }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'activityFilterForm',
    constraints: {},
}), (0, redux_form_1.formValues)('startDate'), (0, redux_form_1.formValues)('endDate'), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(ActivityFilterForm);
