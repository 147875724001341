"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
const components_1 = require("components");
function PrintPage() {
    const [loading, setLoading] = (0, react_1.useState)(true);
    const { terms } = (0, react_router_dom_1.useParams)();
    const printPage = () => {
        setTimeout(() => {
            setLoading(false);
            window.print();
        }, 3000);
    };
    const SRC = terms === 'CCA' ? config_1.URL.CCA : config_1.URL.PATRIOT_ACT;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "print-page", children: [loading && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}), (0, jsx_runtime_1.jsx)("iframe", { className: "proxy-iframe", id: "iframe", src: SRC, title: "Proxy" }), (0, jsx_runtime_1.jsx)("iframe", { className: `iframe ${terms === 'CCA' ? 'financial-terms' : 'patriot-terms'}`, id: "iframe", onLoad: printPage, src: SRC, height: "46427px", title: "Terms" })] }) }));
}
exports.default = PrintPage;
