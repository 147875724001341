"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.reducer = void 0;
const redux_1 = require("redux");
const credit_application_1 = require("./credit_application");
const applicants_1 = require("./applicants");
const receipt_1 = require("./receipt");
const reducerKey = 'creditPortal';
exports.reducerKey = reducerKey;
const reducer = (0, redux_1.combineReducers)({
    [applicants_1.reducerKey]: applicants_1.reducer,
    [credit_application_1.reducerKey]: credit_application_1.reducer,
    [receipt_1.reducerKey]: receipt_1.reducer,
});
exports.reducer = reducer;
