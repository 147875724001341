"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const lp_form_1 = require("lp-form");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const utils_1 = require("utils");
const redux_form_1 = require("redux-form");
function CalculatorForm({ handleSubmit, submitting }) {
    return ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, noValidate: true, children: [(0, jsx_runtime_1.jsx)("div", { className: "calculator-form m-t-30", children: (0, jsx_runtime_1.jsx)("div", { className: "calculator-form-container", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: components_1.InputTip, inputType: lp_components_1.MaskedInput, name: "amount", label: "Total Treatment", required: true, maskOptions: {
                            prefix: '$',
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                        }, parse: utils_1.toAmount }) }) }), (0, jsx_runtime_1.jsx)("div", { className: "calculator-form-submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: `button-warn`, disabled: submitting, submitting: submitting, children: "Estimate Payments" }) })] }));
}
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'CalculatorForm',
    constraints: {},
}))(CalculatorForm);
