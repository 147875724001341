"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaskedInputWithToolTip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
const lp_components_1 = require("lp-components");
const MaskedInputWithToolTip = (props) => {
    const [focus, setFocus] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(lp_components_1.MaskedInput, { onFocus: () => {
                    setFocus(true);
                }, ...props }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: props.input.name, focused: focus, children: (val) => {
                    if ((val || focus) && props.tooltips) {
                        return (0, jsx_runtime_1.jsx)(props.tooltips, {});
                    }
                    return null;
                } })] }));
};
exports.MaskedInputWithToolTip = MaskedInputWithToolTip;
// Keeping this for reference.
// export const SSNInputField = (props) => {
//   const [focus, setFocus] = useState(false)
//   const [visible, setVisible] = useState(false)
//   const [rawSSN, setRawSSN] = useState('')
//   const {
//     input: { onChange },
//   } = props
//   const mask = (v) => {
//     const masked = v.slice(0, 6).replace(/[0-9]/g, 'X')
//     const unmaskedValue = v.length > 6 && v.slice(6, 11)
//     return unmaskedValue ? `${masked}${unmaskedValue}` : masked
//   }
//   const handleChange = (e) => {
//     const { value } = e.target
//     setRawSSN(value)
//     onChange(value)
//   }
//   const handleVisibility = () => {
//     setVisible(!visible)
//     onChange(rawSSN)
//   }
//   useEffect(() => {
//     // setVisible(true)
//     // setRawSSN('123-44-5667')
//   }, [])
//   return (
//     <div>
//       <div className="ssn-input d-flex-center">
//         <div style={{ display: 'none' }}>
//           <props.inputType name={props.input.name} {...props} />
//         </div>
//         <props.inputType
//           name={'ssn' + 'proxy'}
//           value={visible ? rawSSN : mask(rawSSN)}
//           onFocus={() => {
//             setFocus(true)
//           }}
//           onChange={handleChange}
//           {...props}
//         >
//           <div
//             onClick={handleVisibility}
//             style={{ cursor: 'pointer', color: 'gray' }}
//           >
//             {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
//           </div>
//         </props.inputType>
//       </div>
//       <FormSpy name={props.input.name} focused={focus}>
//         {(val) => {
//           if ((val || focus) && props.tooltips) {
//             return <props.tooltips />
//           }
//           return null
//         }}
//       </FormSpy>
//     </div>
//   )
// }
exports.default = { MaskedInputWithToolTip: exports.MaskedInputWithToolTip };
