"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const reducer_1 = require("../../reducer");
const _1 = require(".");
function Home({ currentLocation, allLocations }) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "merchant dashboard", children: [(0, jsx_runtime_1.jsx)(_1.AppWelcome, {}), (0, jsx_runtime_1.jsx)(_1.AppModals, { allLocations: allLocations, currentLocation: currentLocation }), (0, jsx_runtime_1.jsx)(_1.DashboardActions, { currentLocation: currentLocation }), (0, jsx_runtime_1.jsx)(_1.DashboardTables, {})] }) }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
        allLocations: reducer_1.selectors.allLocations(state),
    };
}
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, null))(Home);
