"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const components_1 = require("components");
const merchant_portal_components_1 = require("merchant-portal-components");
const reducer_1 = require("../reducer");
const utils_1 = require("utils");
function AuthorizedExternalLinkButton({ children, className = 'button-secondary', currentPermissions, href, invalid = false, requiredPermission, ...rest }) {
    const unauthorized = (0, utils_1.operationNotPermitted)(currentPermissions, requiredPermission) || invalid;
    return ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizationTooltip, { displayed: unauthorized, children: (0, jsx_runtime_1.jsx)(components_1.ExternalLinkButton, { className: className, href: href, invalid: unauthorized, ...rest, children: children }) }));
}
function mapStateToProps(state) {
    return {
        currentPermissions: reducer_1.selectors.currentPermissions(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(AuthorizedExternalLinkButton);
