"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const redux_form_1 = require("redux-form");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("../components");
const forms_1 = require("../forms");
const merchant_portal_reducer_1 = require("merchant-portal-reducer");
const apiActions = __importStar(require("api-actions"));
const actions = __importStar(require("../actions"));
const config_1 = require("config");
const utils_1 = require("utils");
function Reports({ allLocations, clearAllReports, currentPermissions, getApplicationsReport, getReconciliationReport, getSalesReport, getCreditAvailableReport, reportType: selectedReportType, }) {
    const [ReportDetailView, setReportDetailView] = (0, react_1.useState)(null);
    const { search } = (0, react_router_dom_1.useLocation)();
    const query = (0, utils_1.parseQuery)(search);
    const type = query[config_1.QUERY_KEY.TYPE];
    const reportType = selectedReportType?.value;
    const { startDate: readOnlyStartDate, endDate: readOnlyEndDate } = (0, utils_1.getReportDates)({ dateFilterType: config_1.DATE_SELECTION.MONTH_TO_DATE });
    (0, react_1.useEffect)(() => {
        return () => clearAllReports();
    }, []);
    const handleSubmit = ({ dateFilterType, endDate, locationIds, startDate, }) => {
        setReportDetailView(null);
        const reportDates = (0, utils_1.getReportDates)({
            dateFilterType,
            fromDate: startDate,
            toDate: endDate,
        });
        const selectedLocationIds = locationIds.map(({ value }) => value);
        let reportDataGetter;
        switch (reportType) {
            case config_1.APPLICATION_TRACKING:
                reportDataGetter = getApplicationsReport;
                break;
            case config_1.BANK_RECONCILIATION:
                reportDataGetter = getReconciliationReport;
                break;
            case config_1.SALES:
                reportDataGetter = getSalesReport;
                break;
            case config_1.CREDIT_AVAILABLE:
                reportDataGetter = getCreditAvailableReport;
                break;
            default:
                throw new Error(`Unknown report type [${reportType}]`);
        }
        return reportDataGetter({
            ...reportDates,
            locationIds: selectedLocationIds,
        });
    };
    const handleSubmitSuccess = () => {
        switch (reportType) {
            case config_1.SALES:
                setReportDetailView(() => components_1.SalesReportDetails);
                break;
            case config_1.APPLICATION_TRACKING:
                setReportDetailView(() => components_1.ApplicationReportDetails);
                break;
            case config_1.BANK_RECONCILIATION:
                setReportDetailView(() => components_1.ReconciliationReport);
                break;
            case config_1.CREDIT_AVAILABLE:
                setReportDetailView(() => components_1.CreditAvailableReport);
                break;
            default:
                throw new Error(`Unhandled report type [${reportType}]`);
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "report-page", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Reports" }), (0, jsx_runtime_1.jsxs)("div", { className: "report-page__container", children: [(0, jsx_runtime_1.jsx)(forms_1.ReportsForm, { allLocations: allLocations, initialValues: {
                            dateFilterType: config_1.DATE_SELECTION.MONTH_TO_DATE,
                            locationIds: [],
                            reportType: type,
                            readOnlyStartDate,
                            readOnlyEndDate,
                        }, permissions: currentPermissions, onSubmit: handleSubmit, onSubmitSuccess: handleSubmitSuccess }), ReportDetailView && (0, jsx_runtime_1.jsx)(ReportDetailView, {})] })] }));
}
const reportFormSelector = (0, redux_form_1.formValueSelector)('reportForm');
function mapStateToProps(state) {
    return {
        currentPermissions: merchant_portal_reducer_1.selectors.currentPermissions(state),
        allLocations: merchant_portal_reducer_1.selectors.allLocations(state),
        reportType: reportFormSelector(state, 'reportType'),
    };
}
const mapDispatchToProps = {
    clearAllReports: actions.clearAllReports,
};
const mapApiAuthToProps = {
    getApplicationsReport: apiActions.getApplicationsReport,
    getReconciliationReport: apiActions.getReconciliationReport,
    getSalesReport: apiActions.getSalesReport,
    getCreditAvailableReport: apiActions.getCreditAvailableReport,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(Reports);
